declare var google: any;

/**
 * API VERSION 20190916
 */
export default class Translator {
    private static LANGS = ['en', 'fr'];

    private $selected: JQuery;
    private $selectedimg: JQuery;
    private $langs: JQuery;
    private te: any;

    public constructor() {
        this.initHtml();
    }

    private getDefaultLang(): string {
        let dlang = $('html').data('detectedlang');

        if (dlang != 'pl') {
            if (Translator.LANGS.indexOf(dlang) != -1) {
                return dlang;
            } else {
                return 'en';
            }
        } else {
            return 'pl';
        }
    }

    private getCurrent(): string {
        let lang = this.te != null ? this.te.a.bd : null; // #12045

        return lang;
    }

    private setCurrent(code: string): void {
        localStorage.setItem('lang', code);

        if (code == 'pl') {
            this.te.setEnabled(false);
        } else {
            if (!this.te.b.l) { // Mu::l = iframe#:2.container.goog-te-banner-frame.skiptranslate
                this.te.Gi(); // #12205

                let t = setInterval(() => {
                    if ($('.goog-te-menu-frame').length == 3) {
                        clearInterval(t);

                        this.te.f.ta(code); // #11980

                        this.te.hi(); // #12272
                    }
                }, 300);
            } else {
                this.te.f.ta(code); // #11980
                this.te.hi(); // #12272
            }
        }
    }

    private selectLang(code: string): void {
        this.$langs.removeClass('languages__item--selected');
        this.$langs.filter('.languages__item--' + code).addClass('languages__item--selected');

        this.$selected.text(code);
        this.$selectedimg.prop('src', '/theme/images/lang/' + code + '.png');
    }

    public setLang(code: string): void {
        this.selectLang(code);
        this.setCurrent(code);
    }

    private initHtml(): void {
        $(() => {
            (<any> window).googleTranslateElementInit = () => {
                let params = {
                    pageLanguage: 'pl',
                    includedLanguages: Translator.LANGS.join(','),
                    layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                    autoDisplay: false
                };

                this.te = new google.translate.TranslateElement(params, 'google_translate_element');

                if (localStorage.getItem('lang') == null) {
                    this.setLang(this.getDefaultLang());
                } else {
                    let current = this.getCurrent();

                    if (current == null) {
                        current = 'pl';
                    }

                    this.selectLang(current);
                }

                $languages.addClass('languages--inited');
            }

            let $gte = $('<div id="google_translate_element">');
            $gte.appendTo($('body'));

            $.getScript('//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            // $.getScript('/element_main.js?cb=googleTranslateElementInit');

            let $languages = $('.header .languages');
            this.$selectedimg = $('.languages__selected img', $languages);
            this.$selected = $('.languages__selected span', $languages);
            this.$langs = $('.languages__item', $languages);

            this.$langs.each((i, el) => {
                let $el = $(el);
                let code = $el.data('lang');

                $el.addClass('languages__item--' + code);

                $el.click((e) => {
                    e.preventDefault();

                    this.setLang(code);
                });
            });
        });
    }

    public static init(): void {
        new Translator();
    }
}
