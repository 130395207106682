interface CarInfo {
    producer: string;
    model: string;
    id: number;
}

export default class CarFinder {
    private $el: JQuery;

    private $selector: JQuery;
    private $producer: JQuery;
    private $model: JQuery;
    private $models: JQuery;
    private $search: JQuery;

    private $screens: JQuery;
    private $menu: JQuery;
    private $info: JQuery;
    private $dots: JQuery;

    private selected: CarInfo;

    public constructor() {
        this.initHtml();
    }

    private move($el: JQuery, x: number, force?: boolean, delay?: number): void {
        let dofunc = () => {
            if (force) {
                $el.removeClass('cfanim');
            }

            $el.css('transform', 'translateX(' + x + 'px)');
            $el[0].offsetHeight;

            if (force) {
                $el.addClass('cfanim');
            }
        };

        if (delay) {
            setTimeout(dofunc, delay);
        } else {
            dofunc();
        }
    }

    private showScreen(ix: number, force?: boolean): void {
        let $prev = this.$screens.filter(':visible');
        let $next = this.$screens.eq(ix);

        if (ix != 0) {
            let $items = $('.carfinder__menuitem', this.$menu);
            let $sel = $items.eq(ix - 1);

            $items.removeClass('carfinder__menuitem--selected');
            $sel.addClass('carfinder__menuitem--selected');
        }

        if (force) {
            this.move($prev, -2000, true);
            this.move($next, 0, true);

            if (ix == 0) {
                this.move(this.$selector, 0, true);
                this.move(this.$menu, 600, true);
                this.move(this.$info, 600, true);
            } else {
                this.move(this.$selector, 600, true);
                this.move(this.$menu, 0, true);
                this.move(this.$info, 0, true);
            }
        } else {
            this.move($prev, -2000);
            this.move($next, 2000, true, 0);
            this.move($next, 0, false, 200);

            if (ix == 0) {
                this.move(this.$selector, 0, false, 300);

                this.move(this.$info, 600);
                this.move(this.$menu, 600, false, 300);
            } else {
                this.move(this.$selector, 600, false, 150);

                this.move(this.$menu, 0, false, 500);
                this.move(this.$info, 0, false, 100);
            }
        }
    }

    private selectProducer(name?: string): void {
        if (name == null) {
            this.$producer.val('');
            this.$model.hide();
        } else {
            this.$models.show();
            this.$model.val('');
            this.$models.hide();
            this.$models.filter('[data-name="' + name + '"]').show();

            this.$model.fadeIn();
        }

        this.$search.hide();
    }

    private setModel(id?: number): void {
        let url = '/carfinder/setmodel/' + (id ? id : 0);

        $.post(url, (r) => {
            if (r.status == 'ok') {
                if (id == null) {
                    this.selected = null;
                } else {
                    this.selected = {
                        id: id,
                        producer: r.producer,
                        model: r.model
                    };
                }

                this.updateHtml();
            }
        });
    }

    private updateInfo(info: CarInfo): void {
        $('.carfinder__producer', this.$info).text(info != null ? info.producer : null);
        $('.carfinder__model', this.$info).text(info != null ? info.model : null);
    }

    public updateHtml(force?: boolean): void {
        if (this.selected != null) {
            this.showScreen(1, force);
            this.selectProducer(this.selected.producer);
        } else {
            this.showScreen(0, force);
            this.selectProducer(null);
        }

        this.updateInfo(this.selected);
    }

    public clear(): void {
        this.setModel(null);
    }

    private updateInfoPos($dot: JQuery) {
        let $box: JQuery = $dot.data('box');

        if ($box) {
            let $fig = $dot.parent();
            let figpos = $fig.offset();
            let dotpos = $dot.position();

            $box.css({
                'left': dotpos.left + figpos.left + 30,
                'top': dotpos.top
            });
        }
    }

    private timer = null;

    private setHoverTimers($els: JQuery, endhandler: () => void) {
        let This = this;

        $els.each(function () {
            let $el = $(this);

            $el.hover(
                () => {
                    if (This.timer !== null) {
                        clearTimeout(This.timer);
                        This.timer = null;
                    }
                },

                () => {
                    if (This.timer !== null) {
                        clearTimeout(This.timer);
                    }

                    This.timer = setTimeout(() => {
                        endhandler();
                    }, 500);
                }
            );
        });
    }

    private initDots(): void {
        let This = this;

        this.$dots = $('.carfinder__dot', this.$el);

        this.$dots.each((ix, el) => {
            let $dot = $(el);
            let $doti = $('<span class="carfinder__doti">');

            $doti.hover(
                function () {
                    $('.carinfobox').hide();

                    if (!$dot.hasClass('carfinder__dot--loaded')) {
                        $dot.addClass('carfinder__dot--loaded');

                        let id = $dot.data('id');

                        $.get('/carfinder/listbox/' + id, (html) => {
                            let $box = $(html);
                            $dot.data('box', $box);

                            This.updateInfoPos($dot);

                            This.$el.append($box);

                            This.setHoverTimers($dot.add($box), () => {
                                $box.hide();
                            });
                        });
                    } else {
                        let $box: JQuery = $dot.data('box');

                        This.updateInfoPos($dot);

                        $box.show();
                    }
                }
            );

            $dot.append($doti);
        });
    }

    private initScreens(): void {
        this.$screens = $('.carfinder__screen', this.$el);
        this.$menu = $('.carfinder__menu', this.$el);
        this.$info = $('.carfinder__info', this.$el);

        let This = this;

        let $items = $('.carfinder__menuitem', this.$menu);
        $items.on('click', function (e) {
            e.preventDefault();

            let $this = $(this);
            let ix = $this.index();

            This.showScreen(ix + 1);
        });

        $('.carfinder__clear', this.$info).on('click', (e) => {
            e.preventDefault();

            this.clear();
        });
    }

    private initSelector(): void {
        this.$selector = $('.carfinder__selector', this.$el);
        this.$producer = $('select[name="producer"]', this.$selector);
        this.$model = $('select[name="model"]', this.$selector);
        this.$models = $('option', this.$model);
        this.$search = $('button', this.$selector);

        this.$producer.on('change', (e) => {
            let name = this.$producer.val();

            this.selectProducer(name);
        });

        this.$model.on('change', (e) => {
            this.$search.fadeIn();
        });

        this.$search.on('click', (e) => {
            this.setModel(parseInt(this.$model.val()));
        });
    }

    public initHtml(): void {
        this.$el = $('.carfinder');

        if (this.$el.length) {
            let id = this.$el.data('id');

            if (id) {
                this.selected = {
                    id: parseInt(id),
                    producer: this.$el.data('producer'),
                    model: this.$el.data('model')
                };
            } else {
                this.selected = null;
            }

            this.initScreens();
            this.initSelector();
            this.initDots();
            this.updateHtml(true);

            this.$el.addClass('carfinder--inited');
        }
    }
}
