import './utils/ajax';
import './utils/spinner';
import Cart from './cart/Cart';
import Panel from './Panel';
import Form from '../../modules/core/forms/theme/js/Form';
import Utils from '../../modules/utils/utils-js/theme/js/utils/Utils';
import SiteProduct from './SiteProduct';
import OpenInfo from '../../modules/utils/utils-js/theme/js/utils/OpenInfo';
import ObjectFit from '../../modules/utils/utils-js/theme/js/utils/ObjectFit';
import Switcher from './utils/Switcher';
import Translator from './utils/Translator';
import CarFinder from './utils/CarFinder';

declare let Slider;

class Site {
	public static Cart:Cart = Cart;
	public static Panel:Panel = Panel;
	public static translator: Translator;
	private static carfinder: CarFinder;

	public static onRegisterForm($uid: JQuery): void {
		new Form($uid);

		Switcher.initVal($('input[name="iscompany"]', $uid), 1, $('._js-forcompany', $uid));
		Switcher.initVal($('input[name="iscompany"]', $uid), 1, $('._js-forperson', $uid), true);

		Switcher.initVal($('input[name="sameaddress"]', $uid), 1, $('._js-forsameaddress', $uid), true);

		Switcher.initVal($('input[name="send_iscompany"]', $uid), 1, $('._js-forcompany2', $uid));
		Switcher.initVal($('input[name="send_iscompany"]', $uid), 1, $('._js-forperson2', $uid), true);

		(<any> Site).discover($uid);
	}

	public static onPageHome(): void {
		// new Slider($('.mainslider'), {
		// 	fixed: true,
		// 	max: 1
		// });
	}

	public static discover($parent?: JQuery): void {
		Utils.updateElements($parent);
		Cart.discover($parent);

		Form.initRadios($('.vproduct .radio-items', $parent));
	}

	private static updateFloat() {
		var $header=$('.header');

		var top=$(window).scrollTop();
		var wh=$(window).height();

		$('body').toggleClass('float', top > 0);
		$('body').toggleClass('nofloat', top == 0);
		$('body').toggleClass('body--float-100', top > wh);
		$('body').toggleClass('body--float-ac', top > wh - 130);
	}

	private static initHtml() {
		var $mainmenu=$('.mainmenu');

		$('.menubutton', $mainmenu).click(function(e) {
			e.preventDefault();

			$mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
			$('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
		});

		this.carfinder = new CarFinder();

		this.discover();
	}

	private static initRwd() {
		var $header = $('.header');
		$header.addClass('header--noanim');

		$(window).resize(Site.updateFloat);
		$(window).scroll(Site.updateFloat);

		Site.updateFloat();

		$header.removeClass('header--noanim');
	}

	public static init() {
        (<any> window).Site = Site;

		SiteProduct.initModule();
		OpenInfo.initModule();
        ObjectFit.initModule();
		Form.initModule();

		Translator.init();

		$(document).ready(() => {
			Site.initHtml();
			Site.initRwd();
		});

		$(window).on('load', () => {
			$('body').addClass('body--loaded');

			setTimeout(() => {
				$('body').removeClass('body--loading');
				$('body').trigger('pageloaded');
			}, 1000);
		});
	}
}

Site.init();
